import React, { useCallback, useContext } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { Link } from "./link";
import VideoContext from "../context/video";
import Icon from "./icon";

const Button = ({ data, className, type, videourl, disabled, icon, iconName, ...other }) => {
  const { showVideoModal } = useContext(VideoContext);
  const openVideo = useCallback(
    () => showVideoModal(videourl ? videourl : data?.link),
    [videourl, data?.link, showVideoModal]
  );

  return data?.type?.[0] === "video" ? (
    <button
      className={classNames("button", className, data?.color, "video")}
      onClick={!disabled ? openVideo : null}
    >
      {data?.label}
      {iconName && (
        <Icon
          name={iconName}
          className={classNames("button-icon", data?.color)}
          alt={iconName}
        ></Icon>
      )}
      {icon && (
        <Icon
          name={icon.alternativeIcon}
          url={
            icon.width || icon.width
              ? icon.alternativeIcon?.node?.publicURL
              : icon.alternativeIcon?.node?.resize?.src
          }
          width={icon.width}
          height={icon.height}
          alt={icon.alternativeIcon ? icon.alternativeIcon.value?.description : ""}
        />
      )}
    </button>
  ) : disabled || !data?.link ? (
    <button {...other} disabled={disabled} className={classNames("button", className, data?.color)}>
      {type === "compare" ? <span className="i"></span> : null}
      {data?.label}
      {type === "arrow" ? <span className="i"></span> : null}
      {iconName && (
        <Icon
          name={iconName}
          className={classNames("button-icon", data?.color)}
          alt={iconName}
        ></Icon>
      )}
      {icon && (
        <Icon
          name={icon.alternativeIcon}
          url={
            icon.width || icon.width
              ? icon.alternativeIcon?.node?.publicURL
              : icon.alternativeIcon?.node?.resize?.src
          }
          width={icon.width}
          height={icon.height}
          alt={icon.alternativeIcon ? icon.alternativeIcon.value?.description : ""}
        />
      )}
    </button>
  ) : (
    <Link
      {...other}
      disabled={disabled}
      to={disabled ? null : data.link}
      target={data.target}
      className={classNames("button", className, data.color)}
    >
      {type === "compare" ? <span className="i"></span> : null}
      {data.label}
      {type === "arrow" ? <span className="i"></span> : null}
      {iconName && (
        <Icon
          name={iconName}
          className={classNames("button-icon", data?.color)}
          alt={iconName}
        ></Icon>
      )}
      {icon && (
        <Icon
          name={icon.alternativeIcon}
          url={
            icon.width || icon.width
              ? icon.alternativeIcon?.node?.publicURL
              : icon.alternativeIcon?.node?.resize?.src
          }
          width={icon.width}
          height={icon.height}
          alt={icon.alternativeIcon ? icon.alternativeIcon.value?.description : ""}
        />
      )}
    </Link>
  );
};

export default Button;
export const fragment = graphql`
  fragment ButtonFragment on LiferayCta {
    color: ctaColor
    label: ctaLabel
    link: ctaLink
    target: ctaTarget
    type: ctaType
  }
`;
